import { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type { BillFormParams } from 'src/libs/finbits/Bills/types';
import { BillFormResolver } from 'src/libs/finbits/Bills/validations';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { useInboxItem } from 'src/libs/finbits/Management/NewInboxItems';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';

import { useInboxItemsAttachments } from 'src/features/inbox-items/useInboxItemsAttachments';

type Arguments = {
  inboxItemId?: string;
  linkedEntryId?: string;
};

export default function useBillForm({
  inboxItemId,
  linkedEntryId,
}: Arguments = {}): UseFormReturn<BillFormParams> {
  const { isEnabled: isLinkBillFromInboxItemNewModalEnabled } =
    useExternalFeatureFlag(Feature.LINK_BILL_FROM_INBOX_ITEM_NEW_MODAL);

  // TODO: isso será quem irá ativar/desativar o request para a novo endopint.
  if (isLinkBillFromInboxItemNewModalEnabled) {
    console.log({ inboxItemId, linkedEntryId });
  }

  const { companyId, organizationId } = useCompanyParams();
  const { inboxItem } = useInboxItem({
    companyId,
    organizationId,
    inboxItemId,
  });

  const { attachments } = useInboxItemsAttachments(inboxItem);
  const form = useForm<BillFormParams>({
    resolver: zodResolver(BillFormResolver),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      attachments,
      classifications: {},
      isRecurrenceEnabled: false,
      contactId: null,
    },
  });

  useEffect(() => {
    form.setValue('attachments', attachments);
  }, [form, attachments]);

  return form;
}
