import { Link, Stack } from '@mui/material';
import { useNavigate } from 'react-router';
import { BankIcon } from 'src/mui/_scss';

import { generateCompanyPath } from 'src/router/routes';
import { RouteKey } from 'src/router/types';

import { Typography } from 'src/mui';

import { BTG_SIGNUP_URL } from 'src/libs/finbits/Bank';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import style from './BalanceCTABTG.module.scss';

type Props = {
  onClose?: () => void;
};

export default function BalanceCTABTG({ onClose }: Props) {
  const navigate = useNavigate();
  const { companyId, organizationId } = useCompanyParams();

  function redirectToAccountSettings() {
    onClose?.();

    navigate({
      pathname: generateCompanyPath(RouteKey.ACCOUNTS, {
        companyId,
        organizationId,
      }),
      search: 'action=createAccount',
    });
  }

  return (
    <Stack direction="row" alignItems="center" gap={3} className={style.stack}>
      <BankIcon routingNumber="208" />

      <div>
        <Typography variant="body2">
          Com o BTG, saldos e lançamentos são atualizados em tempo real.
        </Typography>

        <Typography variant="body2">
          <Link href={BTG_SIGNUP_URL} target="_blank">
            Abra uma conta no Banco BTG
          </Link>{' '}
          ou{' '}
          <Link
            onClick={redirectToAccountSettings}
            className={style.linkAccount}
          >
            adicione sua conta
          </Link>
        </Typography>
      </div>
    </Stack>
  );
}
