import { useMemo, useRef, useState } from 'react';

import { CardContent } from '@mui/material';
import type {
  DropResult,
  PreDragActions,
  SensorAPI,
  SnapDragActions,
} from '@hello-pangea/dnd';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ReorderIcon } from 'src/mui/_icons';

import { Tooltip, snackbar } from 'src/mui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import {
  useInfiniteApprovalRules,
  useUpdateApprovalRulePosition,
} from 'src/libs/finbits/ApprovalRules/ApprovalRules';
import type { ApprovalRule } from 'src/libs/finbits/ApprovalRules/types';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { sleep } from 'src/libs/finbits/Time/sleep';

import ApprovalRuleCardSkeleton from 'src/features/approval/approval-rules/ApprovalRuleCard/Skeleton';
import ApprovalRuleCard from 'src/features/approval/approval-rules/ApprovalRuleCard/ApprovalRuleCard';
import CreatePositionedRuleButton from 'src/features/approval/approval-rules/CreatePositionedRuleButton';

import styles from './ApprovalRulesDragableList.module.scss';

enum Direction {
  UP = 'up',
  DOWN = 'down',
}

const ANIMATION_TIME_IN_MS = 300;

function changeRulePosition(
  approvalRules: ApprovalRule[],
  fromIndex: number,
  toIndex: number
) {
  const approvalRulesCopy = Array.from(approvalRules);
  const approvalRule = approvalRulesCopy[fromIndex];

  approvalRulesCopy.splice(fromIndex, 1);
  approvalRulesCopy.splice(toIndex, 0, approvalRule);

  return approvalRulesCopy;
}

export default function ApprovalRulesDragableList() {
  const { isEnabled: isActionEnabled } = useExternalFeatureFlag(
    Feature.APPROVAL_RULES_PAGE_V3
  );
  const { organizationId, companyId } = useCompanyParams();

  const [approvalRules, setApprovalRules] = useState<ApprovalRule[]>([]);

  const {
    ref: scrollBottomRef,
    isLoading,
    isFetching,
  } = useInfiniteApprovalRules(
    { companyId, organizationId },
    {
      onSuccess: (data) => {
        setApprovalRules(data?.pages.map((page) => page.data).flat());
      },
    }
  );

  const sensorAPIRef = useRef<SensorAPI>();

  const { updatePosition } = useUpdateApprovalRulePosition();

  async function move(approvalRule: ApprovalRule, direction: Direction) {
    if (!approvalRules) return;

    const preDrag: PreDragActions | undefined | null =
      sensorAPIRef.current?.tryGetLock(approvalRule.id);

    if (!preDrag) {
      return;
    }

    const drag: SnapDragActions = preDrag.snapLift();

    await sleep(() => {
      if (direction === Direction.DOWN) {
        drag.moveDown();

        return;
      }

      drag.moveUp();
    }, ANIMATION_TIME_IN_MS);

    await sleep(drag.drop, 100);
  }

  function handleUpdatePosition(result: DropResult) {
    if (!result.destination || !approvalRules) return;

    if (result.source.index === result.destination.index) return;

    const isMovingDown = result.source.index - result.destination.index < 0;
    const referenceIndex = isMovingDown
      ? result.destination.index + 1
      : result.destination.index;

    const reference = approvalRules[referenceIndex];

    setApprovalRules((prev) =>
      changeRulePosition(prev, result.source.index, result.destination?.index!)
    );

    updatePosition(
      {
        organizationId,
        companyId,
        referenceRuleId: reference.id,
        approvalRuleId: result.draggableId,
      },
      {
        onSuccess: () => {
          snackbar({
            message: 'Prioridade alterada com sucesso',
            variant: 'success',
          });
        },
        onError: () => {
          snackbar({
            message: 'Ocorreu um erro ao alterar a prioridade',
            variant: 'error',
          });
        },
      }
    );

    return null;
  }

  const sensors = useMemo(() => {
    return [
      (api: SensorAPI) => {
        sensorAPIRef.current = api;
      },
    ];
  }, []);

  return (
    <DragDropContext onDragEnd={handleUpdatePosition} sensors={sensors}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <CardContent
            className={styles.cardContent}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {approvalRules.map((approvalRule, index) => {
              const isLast = index === approvalRules.length - 1;
              const isFirst = index === 0;

              const nextRule = approvalRules[index + 1];
              const isBeforeDefaultRule =
                Boolean(nextRule) && nextRule.defaultRule;
              const isDragDisabled =
                approvalRule.defaultRule ||
                !isActionEnabled ||
                approvalRules.length < 3;

              return (
                <Draggable
                  key={approvalRule.id}
                  draggableId={approvalRule.id}
                  index={index}
                  isDragDisabled={isDragDisabled}
                >
                  {(provided) => {
                    return (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className={styles.draggableCard}
                      >
                        {isActionEnabled && (
                          <CreatePositionedRuleButton
                            isDragging={snapshot.isDraggingOver}
                            approvalRule={approvalRule}
                            isFirst={isFirst}
                          />
                        )}

                        {!isDragDisabled && (
                          <div
                            className={styles.dragableTrigger}
                            {...provided.dragHandleProps}
                          >
                            <Tooltip
                              title="Clique, segure e arraste para subir ou descer a prioridade."
                              disableInteractive
                            >
                              <ReorderIcon className={styles.dragIcon} />
                            </Tooltip>
                          </div>
                        )}
                        <ApprovalRuleCard
                          moveUp={() => move(approvalRule, Direction.UP)}
                          moveDown={() => move(approvalRule, Direction.DOWN)}
                          position={index + 1}
                          approvalRule={approvalRule}
                          isFirst={index === 0}
                          isBeforeDefaultRule={isBeforeDefaultRule}
                          ref={isLast ? scrollBottomRef : null}
                          isDragDisabled={isDragDisabled}
                          hidePositionActions={
                            !isActionEnabled || approvalRules.length < 3
                          }
                        />
                      </div>
                    );
                  }}
                </Draggable>
              );
            })}
            {provided.placeholder}

            {(isFetching || isLoading) && (
              <>
                <ApprovalRuleCardSkeleton classNames={styles.skeleton} />
                <ApprovalRuleCardSkeleton classNames={styles.skeleton} />
                <ApprovalRuleCardSkeleton classNames={styles.skeleton} />
                <ApprovalRuleCardSkeleton classNames={styles.skeleton} />
              </>
            )}
          </CardContent>
        )}
      </Droppable>
    </DragDropContext>
  );
}
