import type { MouseEvent } from 'react';
import { useMemo, useState } from 'react';

import { AlertTitle } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import type { GridColDef } from '@mui/x-data-grid-premium';
import { Alert } from 'src/design-system/components';
import { CurrencyDollarIcon } from 'src/mui/_icons';
import { Table } from 'src/mui/_scss';

import { Badge, DatePicker, Typography } from 'src/mui';

import { useGetAccountsPendencies } from 'src/libs/finbits/Bank/Accounts';
import { Feature, useExternalFeatureFlag } from 'src/libs/finbits/Features';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';

import { TotalSum } from 'src/features/bills-to-pay/BillsToPayDataGrid/GridToolbar';
import { accountColumn } from 'src/features/entries/EntriesDataGrid/columns/accountColumn/accountColumn';
import { amountColumn } from 'src/features/entries/EntriesDataGrid/columns/amountColumn/amountColumn';

import BalanceCTABTG from './BalanceCTABTG';
import styles from './InitialBalanceMenu.module.scss';

export default function InitialBalanceMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { companyId, organizationId } = useCompanyParams();

  const { isEnabled: isBalanceModuleEnabled } = useExternalFeatureFlag(
    Feature.INITIAL_BALANCE_MODULE
  );

  const { data } = useGetAccountsPendencies({
    companyId,
    organizationId,
  });

  const isPending = data?.accounts?.initialBalancePending ?? false;

  const isVisibility = Boolean(anchorEl);

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl((state) => (state ? null : event.currentTarget));
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const columns: GridColDef[] = useMemo(() => {
    const defaultColumns = [
      accountColumn({ editable: false, maxWidth: 160, resizable: false }),
      {
        // TODO: Será componentizado na próxima tarefa
        field: 'Realizado até',
        type: 'realized',
        editable: false,
        resizable: false,
        hideable: true,
        maxWidth: 120,
        flex: 1,
        groupable: false,
        aggregable: false,
        disableExport: true,
      },
      amountColumn({
        editable: false,
        headerName: 'Saldos',
        align: 'right',
        resizable: false,
        maxWidth: 180,
        flex: 1,
      }),
    ];

    return defaultColumns;
  }, []);

  return (
    <>
      {isBalanceModuleEnabled && (
        <>
          <IconButton
            onClick={handleClick}
            size="large"
            aria-label="Saldos"
            aria-describedby="detail-popover"
          >
            <Badge variant="dot" color="error" invisible={!isPending}>
              <CurrencyDollarIcon />
            </Badge>
          </IconButton>

          <Popover
            id="detail-popover"
            open={isVisibility}
            anchorEl={anchorEl}
            onClose={handleClose}
            classes={{ paper: styles.paper }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Typography variant="subtitle1">Saldos realizados</Typography>

            <Alert
              severity="warning"
              variant="outlined"
              className={styles.alert}
            >
              <AlertTitle>
                Cadastre o saldo inicial da conta bancária adicionada
              </AlertTitle>
              Este passo é necessário para que os saldos sejam exibidos e
              atualizados corretamente na FinBits.
            </Alert>

            <Table
              rows={[]}
              columns={columns}
              GridToolBarSlots={{
                TableRootActions: () => (
                  <TotalSum
                    bills={[]}
                    description="Total"
                    titleToolTip="Este valor pode apresentar divergência pois há uma ou mais pendências de inclusão de saldo inicial."
                    showToolTip={true}
                  />
                ),
                QuickActions: () => (
                  <DatePicker
                    slotProps={{
                      inputAdornment: {
                        position: 'start',
                      },
                    }}
                    className={styles.datePicker}
                  />
                ),
              }}
              density="compact"
              disableColumnFilter={true}
              disableColumnSelector={true}
            />

            <BalanceCTABTG onClose={handleClose} />
          </Popover>
        </>
      )}
    </>
  );
}
