import type { Ref, SyntheticEvent } from 'react';
import { forwardRef } from 'react';

import type { AutocompleteValue } from '@mui/material';
import { Button, Chip } from '@mui/material';
import { Select } from 'src/mui/_scss';
import type { Props as SelectProps } from 'src/mui/_scss/Select/Select';
import { PlusIcon } from 'src/mui/_icons';

import { useOpenPortal } from 'src/ui';

import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { FIVE_MINUTES_IN_MS } from 'src/libs/finbits/Time';
import type { Category } from 'src/libs/finbits/Management/FinancialEntries/Categories/types';
import { useCategories } from 'src/libs/finbits/Management/FinancialEntries/Categories';
import type { BalanceType } from 'src/libs/finbits/Organization/Companies/Balances/types';
import { WithAllowedPermission } from 'src/libs/finbits/Permissions';

import CreateCategoryModal from 'src/features/categories/CreateCategoryModal';

import styles from './CategorySelect.module.scss';

type Props<Multiple extends boolean, DisableClearable extends boolean> = {
  type?: BalanceType;
  disableCreateButton?: boolean;
  onChange: (
    event: SyntheticEvent,
    value: AutocompleteValue<Category, Multiple, DisableClearable, false>
  ) => void;
} & Omit<
  SelectProps<Category, Multiple, DisableClearable, false>,
  'options' | 'defaultValue' | 'onChange'
>;

function CategorySelect<
  Multiple extends boolean,
  DisableClearable extends boolean
>(
  {
    type,
    disableCloseOnSelect = true,
    disableCreateButton = false,
    onChange,
    multiple,
    value,
    ...props
  }: Props<Multiple, DisableClearable>,
  ref: Ref<HTMLDivElement>
) {
  const openPortal = useOpenPortal();

  const { companyId, organizationId } = useCompanyParams();

  const { categories, isLoading } = useCategories(
    {
      companyId,
      organizationId,
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
    }
  );

  const activeCategories = categories.filter((category) => {
    if (getSelectedIds(value).some((id) => id === category.id)) {
      return true;
    }

    if (type) {
      return category.active && category.type === type;
    }

    return category.active;
  });

  function handleCreateCategory(event: SyntheticEvent) {
    openPortal(CreateCategoryModal, {
      defaultValues: {
        type,
      },
      disabledFields: Boolean(type) ? ['type'] : undefined,
      onCreateSuccess: (category: Category) => {
        const newValue: any = categoryToFormValue(category, value);

        newValue && onChange(event, newValue);
      },
    });
  }

  return (
    <Select<Category, Multiple, DisableClearable, false>
      disableCloseOnSelect={disableCloseOnSelect}
      aria-label="categoria"
      freeSolo={false}
      placeholder="Digite a categoria"
      value={value}
      {...props}
      multiple={multiple}
      ref={ref}
      onChange={onChange}
      openOnFocus
      options={activeCategories}
      loading={isLoading}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option?.name
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderTags={(tagCategories, getTagProps) => {
        if (!tagCategories || !Array.isArray(tagCategories)) return null;

        return tagCategories.map((category, index) => {
          const tagProps = getTagProps({ index });

          return (
            <Chip
              size="small"
              label={(category as Category).name}
              {...tagProps}
            />
          );
        });
      }}
    >
      {!disableCreateButton && (
        <WithAllowedPermission
          permissions={{ action: 'create', resource: 'categories' }}
        >
          <Button
            startIcon={<PlusIcon />}
            onClick={handleCreateCategory}
            className={styles.createCategoryButton}
          >
            Criar categoria
          </Button>
        </WithAllowedPermission>
      )}
    </Select>
  );
}

function categoryToFormValue(
  category: Category,
  currentFormValue?: AutocompleteValue<Category, boolean, boolean, boolean>
): AutocompleteValue<Category, boolean, boolean, boolean> {
  const valueIsArray = Array.isArray(currentFormValue);

  const newValue = valueIsArray ? [...currentFormValue, category] : category;

  return newValue;
}

function getSelectedIds(
  currentFormValue?: AutocompleteValue<Category, boolean, boolean, false>
) {
  const valueIsArray = Array.isArray(currentFormValue);

  return valueIsArray
    ? currentFormValue.map((v) => v.id)
    : [currentFormValue?.id];
}

export default forwardRef(CategorySelect) as typeof CategorySelect;
