import { snackbar } from 'src/mui';

import type { PortalProps } from 'src/ui';

import { useCreateApprovalRules } from 'src/libs/finbits/ApprovalRules/ApprovalRules';
import type {
  ApprovalRuleFormParams,
  ApprovalRulePostParams,
} from 'src/libs/finbits/ApprovalRules/types';
import { useCompanyParams } from 'src/libs/finbits/Organization/Companies';
import { BillPayableApprovalType } from 'src/libs/finbits/Bills/types';

import ApprovalRuleModal from '../ApprovalRuleModal/ApprovalRuleModal';

type Props = {
  referenceRuleId?: string;
} & PortalProps;

export default function CreateApprovalRuleModal({
  open = true,
  onClose,
  onExit,
  referenceRuleId,
}: Props) {
  const { organizationId, companyId } = useCompanyParams();

  const { createApprovalRules, isLoading } = useCreateApprovalRules();

  const defaultValues: ApprovalRuleFormParams = {
    defaultRule: false,
    description: '',
    approvalType: BillPayableApprovalType.ALL,
    assignees: [],
    conditions: [
      {
        field: null,
        type: null,
      },
    ],
  };

  function handleSubmit(params: ApprovalRulePostParams) {
    createApprovalRules(
      {
        organizationId,
        companyId,
        referenceRuleId,
        ...params,
      },
      {
        onSuccess: () => {
          snackbar({
            variant: 'success',
            title: 'Regra de aprovação criada com sucesso',
            message:
              'Ela será aplicada em lançamentos que atendam a todas as condições definidas.',
          });
          onClose();
        },
        onError: () => {
          snackbar({
            variant: 'error',
            message: 'Ocorreu um erro ao criar a regra de aprovação.',
          });
        },
      }
    );
  }

  return (
    <ApprovalRuleModal
      open={open}
      onClose={onClose}
      onExit={onExit}
      title="Criar regra de aprovação"
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      defaultValues={defaultValues}
    />
  );
}
